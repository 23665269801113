import React from 'react';
import { Container, Header } from 'semantic-ui-react'
import Img from 'gatsby-image'

const HeroSmall = props => {
  const { header, headerImage, heroImage, maxHeight, nameOfOffer } = props.data
  return (
    <Container  textAlign='justified' style={{ width: '100%', minHeight: maxHeight || '480px', height: maxHeight || '480px', margin: 'auto'}}>
      <Img 
        fluid={ heroImage ? heroImage.fluid : headerImage.fluid }
        // fixed={ props.data.hero_image.fixed || props.data.heroImage.fixed }
        style={{
          position: "absolute",
          left: 0,
          top: '100px',
          width: "100%",
          height: maxHeight || '480px',
          minHeight: maxHeight || '480px',
          zIndex: -1
        }}
      />
  
      <Header
        as='h1'
        // color='yellow'
        // content={ props.data.header || props.data.name_of_offer || props.data.nameOfOffer }
        content={ header || nameOfOffer }

        inverted
        textAlign='left'
        style={{
          fontFamily: 'Bangers',
          fontSize: '4.2rem',
          fontWeight: 'normal',
          position: 'absolute',
          top: '420px',
          left: '12%',
          textShadow: '1px 1px 6px rgba(100, 100, 100, 0.7)'
        }}
      />
    </Container>
  )
}; 

export default HeroSmall